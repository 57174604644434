<template>
  <section
    class="interiors"
    @mousewheel="mouseWheel"
    @touchstart="touschSwap"
    @touchend="touschSwap"
  >
    <!-- <Loader :loading="loading" /> -->
    <transition
      v-for="page in pages"
      :key="`interior_${page.id}`"
      name="interiors-page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="interiors-page" v-show="pageCount === page.id">
        <!-- <header class="interiors-page__header">
          <h2 class="text-base interiors-page__header-text">{{ page.name }}</h2>
        </header> -->
        <main class="interiors-page__content">
          <img
            class="interiors-page__image"
            v-webp="page.img"
            :src="page.img"
            :alt="`exterior_${page.id}`"
          />
        </main>
        <footer class="interiors-page__footer">
          <Copyright class="interiors-page__footer-copyright"> Photo by Tom Grotta </Copyright>
          <div class="interiors-page__footer-nav">
            <!-- <IconButton
              v-if="page.id !== 1"
              class="interiors-page__footer-button button--outline-white button--ellipse"
              @click.native="mouseWheel({ deltaY: -100 })"
              @keyup.enter="mouseWheel({ deltaY: -100 })"
            >
              <IconArrowRightLong
                class="interiors-page__footer-nav-arrow interiors-page__footer-nav-arrow--left"
              />
            </IconButton>
          <div v-else class="interiors-page__footer-button--hiden"></div>
          -->
            <IconButton
              class="interiors-page__footer-button interiors-page__footer-button-left"
              :class="{ 'interiors-page__footer-button--disabled': page.id === 1 }"
              :disabled="page.id === 1"
              @click.native="mouseWheel({ deltaY: -100 })"
              @keyup.enter="mouseWheel({ deltaY: -100 })"
            >
              <IconArrowRightLong
                class="interiors-page__footer-nav-arrow interiors-page__footer-nav-arrow--left"
              />
            </IconButton>
            <!-- <p class="text-description interiors-page__footer-page">
              {{ page.id > 9 ? page.id : `0${page.id}` }}
              <span class="text-description interiors-page__footer-page_count">
                /{{ pages.length }}
              </span>
            </p> -->
            <IconButton
              class="interiors-page__footer-button interiors-page__footer-button-right"
              @click.native="mouseWheel({ deltaY: 100 })"
              @keyup.enter.native="mouseWheel({ deltaY: 100 })"
            >
              <IconArrowRightLong
                class="interiors-page__footer-nav-arrow interiors-page__footer-nav-arrow--right"
              />
            </IconButton>
          </div>
        </footer>
      </div>
    </transition>
    <transition
      name="interiors-page__fade"
      @before-enter="beforeEnter"
      @before-leave="beforeLeave"
      @after-leave="afterLeave"
      @after-enter="afterEnter"
    >
      <div class="interiors-page" v-show="pageCount === 18">
        <img
          class="interiors-page__image"
          v-webp="require('@/assets/images/house/interior_19.png')"
          src="@/assets/images/house/interior_19.png"
          alt="interior_19"
        />
        <div class="interiors-page__content interiors-page__content--first">
          <div class="interiors-page__body interiors-page__body_finished">
            <h2 class="text-sub-title interiors-page__body-sub-title">View: Collection</h2>
            <div class="interiors-page__body-footer">
              <BaseButton
                class="interiors-page__body-footer-button button--white button--ellipse button--lg"
                text="Collection"
                @click="openCategories"
              />
              <BaseButton
                class="interiors-page__body-footer-button button--white button--ellipse button--lg"
                text="Creators"
                @click="openArtistsPage"
              />
              <BaseButton
                class="interiors-page__body-footer-button button--outline-white button--ellipse button--lg"
                text="Back to Start"
                @click="toStart"
              />
              <div class="interiors-page__body-footer-line"></div>
            </div>
          </div>
          <div class="interiors-page__footer">
            <Copyright class="interiors-page__footer-copyright"> Photo by Tom Grotta </Copyright>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import TourMode from "@/components/interiors/TourMode.vue";
import Copyright from "@/components/Copyright.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import IconArrow from "@/components/icons/IconArrowTop.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconArrowRightLong from "@/components/icons/IconArrowRightLong.vue";

export default {
  components: {
    Loader,
    BaseButton,
    TourMode,
    Copyright,
    IconInfo,
    IconArrow,
    IconButton,
    IconArrowRightLong,
  },
  data() {
    return {
      pageCount: 1,
      pagesCount: 18,
      loading: false,
      scrollDeltaY: 0,
      animationMove: false,
      pages: [
        {
          id: 1,
          text: [
            { title: "Collage: ", authors: [{ name: "Richard Meier", link: "" }] },
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Françoise Grossen", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Toshiko Takaezu", link: "" },
                { name: "Rudy Autio", link: "" },
              ],
            },
          ],
          //text: "Collage: Richard Meier; \n \n Fiber: Sheila Hicks, Françoise Grossen; \n \n Ceramics: Toshiko Takaezu, Rudy Autio",
          name: "Front Entrance/ Door",
          img: import("@/assets/images/house/interior_2.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 2,
          text: [
            { title: "Lamp: ", authors: [{ name: "Thomas Hucker", link: "" }] },
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Helena Hernmarck", link: "" },
                { name: "Dominic DiMare", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Maija Grotell", link: "" },
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { name: "Kenneth Ferguson", link: "" },
              ],
            },
            { title: "Bird: ", authors: [{ name: "John McQueen", link: "" }] },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Hiroki Takada", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
                { name: "Hans Wegner", link: "" },
              ],
            },
            { title: "Baskets: ", authors: [{ name: "Dorothy Gill Barnes", link: "" }] },
            { title: "Fireplace: ", authors: [{ name: "David Ling", link: "" }] },
          ],
          //text: "Lamp: Thomas Hucker; \n \n Fiber: Sheila Hicks, Helena Hernmarck, Dominic DiMare; \n \n Ceramics: Maija Grotell, Toshiko Takaezu, Wayne Higby, Kenneth Ferguson; \n \n Bird: John McQueen; \n \n Furniture: Joyce & Edgar Anderson, Hiroki Takada, Ludwig Mies van der Rohe, Hans Wegner; \n \n Baskets: Dorothy Gill Barnes; \n \n Fireplace: David Ling",
          name: "Living Room View 1",
          img: import("@/assets/images/house/interior_3.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 3,
          text: [
            { title: "Lamp: ", authors: [{ name: "Thomas Hucker", link: "" }] },
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Mariette Rousseau-Vermette", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Maija Grotell", link: "" },
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { title: "Bird: ", authors: [{ name: "John McQueen", link: "" }] },
              ],
            },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Hiroki Takada", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
              ],
            },
            { title: "Baskets: ", authors: [{ name: "Dorothy Gill Barnes", link: "" }] },
          ],
          //text: "Lamp: Thomas Hucker; \n \n Fiber: Sheila Hicks, Mariette Rousseau-Vermette; \n \n Ceramics: Maija Grotell, Toshiko Takaezu, Wayne Higby; \n \n Bird: John McQueen; \n \n Furniture: Joyce & Edgar Anderson, Hiroki Takada, Ludwig Mies van der Rohe; \n \n Baskets: Dorothy Gill Barnes",
          name: "Living Room View 2",
          img: import("@/assets/images/house/interior_3.5.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 4,
          text: [
            { title: "Lamp: ", authors: [{ name: "Thomas Hucker", link: "" }] },
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Mariette Rousseau-Vermette", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Maija Grotell", link: "" },
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { title: "Bird: ", authors: [{ name: "John McQueen", link: "" }] },
              ],
            },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Hiroki Takada", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
              ],
            },
            { title: "Baskets: ", authors: [{ name: "Dorothy Gill Barnes", link: "" }] },
          ],
          //text: "Lamp: Thomas Hucker; \n \n Fiber: Sheila Hicks, Mariette Rousseau-Vermette; \n \n Ceramics: Maija Grotell, Toshiko Takaezu, Wayne Higby; \n \n Bird: John McQueen; \n \n Furniture: Joyce & Edgar Anderson, Hiroki Takada, Ludwig Mies van der Rohe; \n \n Baskets: Dorothy Gill Barnes",
          name: "Living Room View 3",
          img: import("@/assets/images/house/interior_4.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 5,
          text: [
            { title: "Lamp: ", authors: [{ name: "Thomas Hucker", link: "" }] },
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Mariette Rousseau-Vermette", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Maija Grotell", link: "" },
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { title: "Bird: ", authors: [{ name: "John McQueen", link: "" }] },
              ],
            },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Hiroki Takada", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
              ],
            },
            { title: "Baskets: ", authors: [{ name: "Dorothy Gill Barnes", link: "" }] },
          ],
          //text: "Lamp: Thomas Hucker; \n \n Fiber: Sheila Hicks, Mariette Rousseau-Vermette; \n \n Ceramics: Maija Grotell, Toshiko Takaezu, Wayne Higby; \n \n Bird: John McQueen; \n \n Furniture: Joyce & Edgar Anderson, Hiroki Takada, Ludwig Mies van der Rohe; \n \n Baskets: Dorothy Gill Barnes",
          name: "View from Living Room",
          img: import("@/assets/images/house/interior_4.5.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 6,
          text: [
            { title: "Collage: ", authors: [{ name: "Richard Meier", link: "" }] },
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Helena Hernmarck", link: "" },
                { name: "Françoise Grossen", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { name: "Ken Ferguson", link: "" },
                { name: "Maija Grotell", link: "" },
              ],
            },
            { title: "Basket: ", authors: [{ name: "John McQueen", link: "" }] },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Hiroki Takada", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
                { name: "Hans Wegner", link: "" },
              ],
            },
            { title: "Fireplace: ", authors: [{ name: "David Ling", link: "" }] },
          ],
          //text: "Collage: Richard Meier; \n \n Fiber: Sheila Hicks, Helena Hernmarck, Françoise Grossen; \n \n Ceramics: Toshiko Takaezu, Wayne Higby, Ken Ferguson, Maija Grotell; \n \n Basket: John McQueen; \n \n Furniture: Joyce & Edgar Anderson, Hiroki Takada, Ludwig Mies van der Rohe, Hans Wegner; \n \n Fireplace: David Ling",
          name: "Front Hall",
          img: import("@/assets/images/house/interior_5.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 7,
          text: [
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Françoise Grossen", link: "" },
                { name: "Dominic Di Mare", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { name: "Rudy Autio", link: "" },
              ],
            },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Wendell Castle", link: "" },
                { name: "Hans Wegner", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
              ],
            },
            { title: "Collage: ", authors: [{ name: "Richard Meier", link: "" }] },
            { title: "Bird: ", authors: [{ name: "John McQueen", link: "" }] },
            { title: "Lamp: ", authors: [{ name: "Thomas Hucker", link: "" }] },
          ],
          //text: "Fiber: Sheila Hicks, Françoise Grossen, Dominic Di Mare, Helena Hernmarck; \n \n Ceramics: Toshiko Takaezu, Wayne Higby, Rudy Autio; \n \n Furniture: Joyce & Edgar Anderson, Wendell Castle, Hans Wegner, Ludwig Mies van der Rohe; \n \n Collage: Richard Meier; \n \n Bird: John McQueen; \n \n Lamp: Thomas Hucker",
          name: "Dining Room/ Front Hall",
          img: import("@/assets/images/house/interior_6.1.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 8,
          text: [
            {
              title: "Fiber: ",
              authors: [
                { name: "Sheila Hicks", link: "" },
                { name: "Helena Hernmark", link: "" },
                { name: "Dominic di Mare", link: "" },
              ],
            },
            {
              title: "Baskets: ",
              authors: [
                { name: "Dorothy Gill Barnes", link: "" },
                { name: "John McQueen", link: "" },
              ],
            },
            { title: "Lamp: ", authors: [{ name: "Thomas Hucker", link: "" }] },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Toshiko Takaezu", link: "" },
                { name: "Wayne Higby", link: "" },
                { name: "Kenneth Ferguson", link: "" },
                { name: "Maija Grotell", link: "" },
              ],
            },
            {
              title: "Furniture: ",
              authors: [
                { name: "Joyce & Edgar Anderson", link: "" },
                { name: "Hiroki Takada", link: "" },
                { name: "Ludwig Mies van der Rohe", link: "" },
                { name: "Hans Wegner", link: "" },
              ],
            },
            { title: "Fireplace: ", authors: [{ name: "David Ling", link: "" }] },
          ],
          //text: "Fiber: Sheila Hicks, Helena Hernmark, Dominic di Mare; \n \n Baskets: Dorothy Gill Barnes, John McQueen; \n \n Lamp: Thomas Hucker; \n \n Ceramics: Toshiko Takaezu, Wayne Higby, Kenneth Ferguson, Maija Grotell; \n \n Furniture: Joyce & Edgar Anderson, Hiroki Takada, Ludwig Mies van der Rohe, Hans Wegner; \n \n Fireplace: David Ling",
          name: "Living Room View 4",
          img: import("@/assets/images/house/interior_7.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 9,
          text: [
            {
              title: "Fiber: ",
              authors: [
                { name: "Ritzi Jacobi", link: "" },
                { name: "Mariette Rousseau-Vermette", link: "" },
              ],
            },
            { title: "Fireplace: ", authors: [{ name: "David Ling", link: "" }] },
          ],
          //text: "Fiber: Ritzi Jacobi, Mariette Rousseau-Vermette; \n \n Fireplace: David Ling",
          name: "Stairwell",
          img: import("@/assets/images/house/interior_9.jpg").then(data => {
            return data.default;
          }),
        },
        /* {
          id: 9,
          text: [
            {
              title: "Fiber: ",
              authors: [
                { name: "Ritzi Jacobi", link: "" },
                { name: "Mariette Rousseau-Vermette", link: "" },
              ],
            },
            { title: "Bowls: ", authors: [{ name: "Bob Stocksdale", link: "" }] },
          ],
          //text: "Fiber: Ritzi Jacobi, Mariette Rousseau-Vermette; \n \n Bowls: Bob Stocksdale",
          name: "Sandy’s Desk",
          img: import("@/assets/images/house/interior_10.jpg").then(data => {
            return data.default;
          }),
        }, */
        {
          id: 10,
          text: [
            {
              title: "Wood: ",
              authors: [
                { name: "Gyöngy Laky", link: "" },
                { name: "John McQueen", link: "" },
              ],
            },
            { title: "Collage: ", authors: [{ name: "Ed Rossbach", link: "" }] },
            { title: "Fiber: ", authors: [{ name: "Sheila Hicks", link: "" }] },
            { title: "Ceramics: ", authors: [{ name: "Edwin & Mary Scheier", link: "" }] },
          ],
          //text: "Wood: Gyöngy Laky, John McQueen; \n \n Collage: Ed Rossbach; \n \n Fiber: Sheila Hicks; \n \n Ceramics: Edwin & Mary Scheier",
          name: "Master Hallway",
          img: import("@/assets/images/house/interior_11.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 11,
          text: [
            {
              title: "Lamp: ",
              authors: [{ name: "Bertil Vallien", link: "" }],
            },
            {
              title: "Basket sculptures: ",
              authors: [
                { name: "John McQueen", link: "" },
                { name: "Dawn MacNutt", link: "" },
              ],
            },
            { title: "Ceramics: ", authors: [{ name: "Edwin & Mary Scheier", link: "" }] },
            { title: "Furniture: ", authors: [{ name: "Mira Nakashima", link: "" }] },
            { title: "Fiber: ", authors: [{ name: "Kaija Sanema Harris", link: "" }] },
          ],
          //text: "Lamp: Bertil Vallien; \n \n Basket sculptures: John McQueen, Dawn MacNutt; \n \n Ceramics: Edwin & Mary Scheier; \n \n Furniture: Mira Nakashima; \n \n Fiber: Kaija Sanema Harris",
          name: "Upstairs Den",
          img: import("@/assets/images/house/interior_12.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 12,
          text: [
            {
              title: "Basket sculptures: ",
              authors: [
                { name: "John McQueen", link: "" },
                { name: "Norma Minkowitz", link: "" },
                { name: "Mary Giles", link: "" },
              ],
            },
            {
              title: "Jewelry: ",
              authors: [
                { name: "Laurie Hall", link: "" },
                { name: "Gerd Rothman", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Beatrice Wood", link: "" },
                { name: "Jack Earle", link: "" },
                { name: "Edwin & Mary Scheier", link: "" },
                { name: "Kenneth Ferguson", link: "" },
              ],
            },
          ],
          //text: "Basket sculptures: John McQueen, Norma Minkowitz, Mary Giles; \n \n Jewelry: Laurie Hall, Gerd Rothman; \n \n Ceramics: Beatrice Wood, Jack Earle, Edwin & Mary Scheier, Kenneth Ferguson",
          name: "Master Bedroom View 1",
          img: import("@/assets/images/house/interior_13.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 13,
          text: [
            {
              title: "Fiber: ",
              authors: [{ name: "Dawn MacNutt", link: "" }],
            },
          ],
          //text: "Fiber: Dawn MacNutt",
          name: "Dining Room",
          img: import("@/assets/images/house/interior_14.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 14,
          text: [
            {
              title: "Bird: ",
              authors: [{ name: "John McQueen", link: "" }],
            },
            {
              title: "Ceramics: ",
              authors: [
                { name: "Robert Turner", link: "" },
                { name: "Paul Soldner", link: "" },
              ],
            },
          ],
          //text: "Bird: John McQueen; \n \n Ceramics: Robert Turner, Paul Soldner",
          name: "Downstairs Den",
          img: import("@/assets/images/house/interior_15.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 15,
          text: [
            {
              title: "Kitchen - Left: \n left to right (top shelf): ",
              authors: [
                { name: "Bill Sax", link: "" },
                { name: "Ineke Hans", link: "" },
                { name: "Jonathan Glick", link: "" },
                { name: "Lonny van Ryswyck and Nadine Sterk", link: "" },
                { name: "Adrian Saxe", link: "" },
                { name: "Los Castillo", link: "" },
                { name: "Jeff Oestreich", link: "" },
                { name: "Robert Winokur", link: "" },
              ],
            },
            {
              title: "Countertop: ",
              authors: [
                { name: "William Wyman", link: "" },
                { name: "Karen Karnes", link: "" },
                { name: "Nicholas Homoky", link: "" },
              ],
            },
            {
              title: "Wood bowl: ",
              authors: [{ name: "Bob Stocksdale", link: "" }],
            },
            {
              title: "Holloware (center table): ",
              authors: [{ name: "Charles Crowley", link: "" }],
            },
            {
              title: "Stools: ",
              authors: [{ name: "Joyce & Edgar Anderson", link: "" }],
            },
            {
              title: "\n Kitchen - Right: \n left to right (teapots): ",
              authors: [
                { name: "Anne Holland", link: "" },
                { name: "Peter Voulkos", link: "" },
                { name: "Karen Karnes", link: "" },
                { name: "William Wyman", link: "" },
                { name: "Ettore Sottsass", link: "" },
                { name: "Mary Roehm", link: "" },
                { name: "Ragnar Naess", link: "" },
                { name: "Mark Pharis", link: "" },
                { name: "Anne Holland", link: "" },
                { name: "Rolf Simmemark", link: "" },
                { name: "Nicholas Homoky", link: "" },
              ],
            },
            {
              title: "Architect designs: ",
              authors: [
                { name: "Walter Gropius", link: "" },
                { name: "Russell Wright", link: "" },
                { name: "Hans Bacher", link: "" },
                { name: "Henning Koppel", link: "" },
              ],
            },
            {
              title: "Architects Holloware: ",
              authors: [
                { name: "Richard Meier", link: "" },
                { name: "Arne Jacobsen", link: "" },
                { name: "Henning Koppel", link: "" },
                { name: "Frank Gehry", link: "" },
              ],
            },
          ],
          //text: `Kitchen - Left: \n left to right (top shelf): Bill Sax, Ineke Hans, Jonathan Glick, Lonny van Ryswyck and Nadine Sterk, Adrian Saxe, Los Castillo, Jeff Oestreich, Robert Winokur; \n \n Countertop: William Wyman, Karen Karnes, Nicholas Homoky; \n \n Wood bowl: Bob Stocksdale; \n \n Holloware (center table): Charles Crowley; \n \n Stools: Joyce & Edgar Anderson \n \n \n Kitchen - Right: \n left to right (teapots): Anne Holland, Peter Voulkos, Karen Karnes, William Wyman, Ettore Sottsass, Mary Roehm, Ragnar Naess, Mark Pharis, Anne Holland, Rolf Simmemark, Nicholas Homoky; \n \n Architect designs: Walter Gropius, Russell Wright, Hans Bacher, Henning Koppel; \n \n Architects Holloware: Richard Meier, Arne Jacobsen, Henning Koppel, Frank Gehry`,
          name: "Kitchen",
          img: import("@/assets/images/house/interior_16.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 16,
          text: [
            {
              title: "Ceramics: ",
              authors: [{ name: "Toshiko Takaezu", link: "" }],
            },
          ],
          //text: "Ceramics: Toshiko Takaezu",
          name: "Master Bathroom",
          img: import("@/assets/images/house/interior_17.jpg").then(data => {
            return data.default;
          }),
        },
        {
          id: 17,
          text: [
            {
              title: "Furniture: ",
              authors: [
                { name: "Richard Meier", link: "" },
                { name: "Sam Maloof", link: "" },
              ],
            },
            {
              title: "Ceramics: ",
              authors: [{ name: "Jack Earle", link: "" }],
            },
            {
              title: "Fiber: ",
              authors: [
                { name: "Kaija Sanema Harris", link: "" },
                { name: "Mariette Rousseau-Vermette", link: "" },
              ],
            },
            {
              title: "Paper jewelry: ",
              authors: [{ name: "Kiff Slemmons", link: "" }],
            },
            {
              title: "Bracelet tree: ",
              authors: [
                { name: "Thomas Hucker", link: "" },
                { name: "assorted artists", link: "" },
              ],
            },
            {
              title: "Braclets: (by row from top, left to right): ",
              authors: [
                { name: "Charles Loloma", link: "" },
                { name: "Lisa Gralnick", link: "" },
                { name: "Martin Page", link: "" },
                { name: "Gerd Rothman", link: "" },
                { name: "Georg Jensen", link: "" },
                { name: "Bent Peterson", link: "" },
                { name: "Pavel Opočenský", link: "" },
                { name: "Lella Vignelli", link: "" },
                { name: "David Watkins", link: "" },
                { name: "Joyce Anderson", link: "" },
                { name: "Brigitte von Wysiecki", link: "" },
                { name: "Françoise van den Bosch", link: "" },
                { name: "Fritz Maierhofer", link: "" },
                { name: "William Clark", link: "" },
                { name: "Karyl Sisson", link: "" },
                { name: "Richard Barth", link: "" },
              ],
            },
          ],
          //text: "Furniture: Richard Meier, Sam Maloof; \n \n Ceramics: Jack Earle; \n \n Fiber: Kaija Sanema Harris, Mariette Rousseau-Vermette; \n \n Paper jewelry: Kiff Slemmons; \n \n Bracelet tree: Thomas Hucker; assorted artists; \n \n Braclets: (by row from top, left to right): Charles Loloma, Lisa Gralnick, Martin Page, Gerd Rothman, Georg Jensen, Bent Peterson, Pavel Opočenský, Lella Vignelli, David Watkins, Joyce Anderson, Brigitte von Wysiecki, Françoise van den Bosch, Fritz Maierhofer, William Clark, Karyl Sisson, David Watkins, Richard Barth",
          name: "Master Bedroom View 2",
          img: import("@/assets/images/house/interior_18.jpg").then(data => {
            return data.default;
          }),
        },
      ],
    };
  },

  head: {
    title: function () {
      return {
        inner: `GH - Inside`,
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {
    ...mapGetters("publicArtists", {
      artistsList: "getArtistsList",
    }),
  },

  beforeRouteEnter(to, from, next) {
    const pagesCount = 18;
    if (to.params.id > pagesCount || to.params.id < 1) next({ path: "/interiors/1" });
    else
      next(vm => {
        vm.pageCount = +to.params.id;
      });
  },

  async created() {
    this.loading = true;
    await this.apiGetArtistsList();

    this.pages?.forEach(async page => {
      const data = await page.img;
      page.img = data;
    });

    this.pages?.forEach(async page => {
      page.text.forEach(item => {
        item.authors.forEach(author => {
          this.artistsList.map(artist => {
            if (artist.name === author.name) {
              author.link = `/creators/${artist.slug}`;
            }
          });
        });
      });
    });
    document.addEventListener("keydown", this.keydown);
    this.loading = false;
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
  },

  watch: {
    "$route.path": function () {
      if (+this.$route.params.id === 1) this.toStart();
    },
  },

  methods: {
    ...mapActions("publicArtists", ["apiGetArtistsList"]),
    beforeEnter(el) {
      this.animationMove = true;
      el.classList.add(`interiors-page__fade-enter${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
    },
    afterEnter(el) {
      el.classList.remove(`interiors-page__fade-enter${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
      this.animationMove = false;
    },
    beforeLeave(el) {
      this.animationMove = true;
      el.classList.add(`interiors-page__fade-leave${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
    },
    afterLeave(el) {
      el.classList.remove(`interiors-page__fade-leave${this.scrollDeltaY > 0 ? "-up" : "-down"}`);
      this.animationMove = false;
    },
    goBack() {
      this.$router.push({ path: "/" });
    },
    openCategories() {
      this.$router.push({ path: "/art/collection" });
      // this.$router.push({ path: "/art/collection" });
    },
    openArtistsPage() {
      this.$router.push({ path: "/creators" });
    },
    openAuthorPage(link) {
      if (link) {
        this.$router.push({ path: link });
      }
    },
    toStart() {
      this.scrollDeltaY = -1;
      this.$router.push({ path: "/interiors/1" });
      this.pageCount = 1;
    },
    mouseWheel(event) {
      if (!this.animationMove && !this.tourActive) {
        this.scrollDeltaY = event.deltaY;
        if (event.deltaY >= 30 && this.pageCount < this.pagesCount) {
          this.pageCount++;
          this.$router.push({ path: `/interiors/${this.pageCount}` });
        }
        if (event.deltaY <= -30 && this.pageCount > 1) {
          this.pageCount--;
          this.$router.push({ path: `/interiors/${this.pageCount}` });
        }
      }
    },
    keydown(event) {
      switch (event.code) {
        case "ArrowUp":
          this.mouseWheel({ deltaY: -100 });
          break;
        case "ArrowDown":
          this.mouseWheel({ deltaY: 100 });
          break;
      }
    },
    touschSwap(event) {
      if (!this.animationMove && !this.tourActive) {
        if (event.type === "touchstart") {
          this.scrollDeltaY = event.changedTouches[0].clientY;
        }
        if (event.type === "touchend") {
          const newScrollDeltaY = event.changedTouches[0].clientY;
          const deltaY = this.scrollDeltaY - newScrollDeltaY;
          this.mouseWheel({ deltaY });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.interiors {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  &-page {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__fade {
      &-enter-active,
      &-leave-active {
        transition: all 1s ease;
      }
      &-enter {
        &-up {
          animation: pageEnterUp 1s ease-in-out;
        }
        &-down {
          animation: pageLeaveDown 1s ease-in-out;
        }
      }
      &-leave {
        &-up {
          animation: pageLeaveUp 1s ease-in-out;
        }
        &-down {
          animation: pageEnterDown 1s ease-in-out;
        }
      }
    }
    &__image {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: $xxs) {
        width: auto;
        max-width: none;
      }
      &--bg {
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
    &__button {
      width: max-content;
      min-width: 22rem;
      &--start {
        margin-top: 15px;
      }
      &--back {
        position: absolute;
        z-index: 999;
        left: 50px;
        top: 70px;
        height: 4rem;
        min-width: auto;
        font-size: 1.8rem;
        @media (max-width: $xxs) {
          left: 25px;
          top: 50px;
        }
      }
    }
    &__header {
      padding: 50px 50px 20px;
      min-height: calc(7rem + 70px);
      display: flex;
      align-items: center;
      flex-shrink: 0;
      @media (max-width: $xxs) {
        min-height: calc(6rem + 55px);
        padding: 100px 25px 15px;
      }
      &-text {
        max-width: 842px;
        @media (max-width: $xxl) {
          max-width: 720px;
        }
        @media (max-width: $xxs) {
          max-width: 60%;
          font-size: 1.8rem;
        }
      }
    }
    &__content {
      position: relative;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      &--first {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
    &__body {
      padding-left: 16.4rem;
      margin-bottom: 75px;
      width: max-content;
      @media (max-width: $lg) {
        width: 100%;
      }
      @media (max-width: $xxs) {
        padding: 0 25px;
        width: 100%;
      }
      &_top {
        height: 100%;
        margin-top: 13.3rem;
      }
      &_finished {
        padding-left: 38rem;
        width: 100%;
        @media (max-width: $xxs) {
          padding: 0 0 0 25px;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
        max-width: 100%;
        transition: all 0.5s ease;
      }
      &-mobile-block {
        display: flex;
        align-items: center;
        height: 100%;
        overflow: hidden;
      }
      &-button {
        width: 100%;
        min-width: 21.4rem;
        margin-top: 38px;
        @media (max-width: $md) {
          display: none;
        }
        &-info {
          display: none;
          border: none;
          padding: 0;
          transition: all 0.5s ease;
          @media (max-width: $xxs) {
            display: inline-flex;
          }
          &-arrow {
            margin-left: 7px;
            transform: rotate(-90deg);
            transition: all 0.3s ease;
            &_active {
              transform: rotate(90deg);
            }
          }
        }
      }
      &-title {
        margin-bottom: 22px;
      }
      &-sub-title {
        &_margin {
          margin-bottom: 20px;
        }
        &_left_top {
          position: absolute;
          top: 10.2rem;
          left: 16.4rem;
          @media (max-width: $xxs) {
            top: 20.7rem;
            left: 2.5rem;
            margin-right: 2.5rem;
          }
        }
      }
      &-text {
        font-size: 3.6rem;
        letter-spacing: -0.62px;
        line-height: 1.3;
      }
      &-description {
        white-space: pre-line;
        overflow-y: scroll;
        max-width: 997px;
        &--grow {
          flex-grow: 1;
        }
        @media (max-width: $xxs) {
          width: 100%;
          min-width: 32rem;
          max-width: 38rem;
        }
        @media (max-width: $xxxs) {
          min-width: 27rem;
        }
        @media (max-width: $xxxxs) {
          min-width: 22rem;
        }

        &_item {
          margin-bottom: 2.2rem;
          .clickable {
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      &-footer {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 5rem;
        @media (max-width: $xxs) {
          margin-top: 3rem;
          flex-wrap: wrap;
          gap: 10px 0;
        }
        &-button {
          min-width: 20rem;
          width: max-content;
          flex-shrink: 0;
          &.button--white {
            margin-right: 15px;
          }
          @media (max-width: $xxs) {
            min-width: 15rem;
          }
        }
        &-line {
          height: 1px;
          min-width: 2.9rem;
          width: 100%;
          background-color: $white;
          @media (max-width: $xxs) {
            max-width: 50%;
          }
        }
      }
    }
    &__footer {
      //position: relative;
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px 0;
      padding: 20px 50px;
      @media (max-width: $xxs) {
        padding: 20px 25px 30px;
        flex-wrap: wrap-reverse;
        justify-content: center;
        align-items: center;
      }
      &-nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 270px;
        background-color: rgba(17, 17, 17, 1);
        padding: 10px 50px;
        border-radius: 50px;
        @media screen and (max-width: $xxs) {
          position: static;
          left: 0;
          transform: none;
          max-width: 250px;
        }
        &-arrow {
          cursor: pointer;
          width: 36px;
          height: 12px;
          &::v-deep g {
            transition: all 0.3s ease;
            fill: $white;
          }
          &--right {
            transform: rotate(180deg) scale(1.1);
          }
          &--left {
            transform: scale(1.1);
          }
        }
      }
      &-button {
        width: max-content;
        height: max-content;
        padding: 10px;
        border: none;
        background: transparent;
        transition: all 0.3s ease-in-out;
        svg {
          &::v-deep g {
            fill: $white;
          }
        }
        &:hover {
          // background: $white;
          // &::v-deep g {
          //   fill: $black;
          // }
          background: transparent;
          &::v-deep g {
            fill: $white;
          }
        }
        &-right {
          &:hover {
            margin-right: -6px;
          }
        }
        &-left {
          &:hover {
            margin-left: -6px;
          }
        }
        &--hiden {
          width: 56px;
          height: 29px;
          padding: 10px;
        }
      }
      &-copyright {
        @media screen and (max-width: $xxs) {
          width: 100%;
        }
      }
      &-description {
        cursor: pointer;
        font-family: $fontBodoni;
        line-height: 2.44;
      }
      &-page {
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.2;
        @media screen and (max-width: $xxs) {
        }
        &_count {
          font-size: 2rem;
          font-weight: 600;
          line-height: 2.2;
          opacity: 0.6;
        }
      }
    }
  }
}
@keyframes pageEnterUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes pageLeaveUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes pageEnterDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes pageLeaveDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes showInfo {
  from {
    max-width: 100%;
  }
  to {
    max-width: 0;
  }
}
</style>

