<template>
  <transition name="tour-mode__fade">
    <div class="tour-mode" v-show="tourActive">
      <transition name="tour-mode__fade">
        <div class="tour-mode-page" v-show="!zoomZoneActive">
          <img
            class="tour-mode__image"
            v-webp="tourePage.img"
            :src="tourePage.img"
            alt="Toure mode image"
          />
          <div class="tour-mode__content">
            <div class="tour-mode__control">
              <IconButton
                v-for="zoomZone in tourePage.zoomZones"
                :key="`zoomZone-${zoomZone.id}`"
                :style="{ left: zoomZone.coordinates[0], top: zoomZone.coordinates[1] }"
                class="tour-mode__button-zoom button--outline-black button--ellipse button--lg button--right-icon"
                text="Zoom In"
                @click="zoomIn(zoomZone.id)"
              >
                <template #right>
                  <IconPlus width="16" height="16" color="#fff"></IconPlus>
                </template>
              </IconButton>
              <div
                v-for="(art, index) in tourePage.arts"
                :key="`art-${art.id}`"
                class="tour-mode__art"
                :class="{ reverse: art.alignment === 'Left' }"
                :style="getCoordinates(art.coordinates)"
                @mouseleave="showArtLink(null)"
              >
                <div
                  class="tour-mode__art-circle"
                  @mouseover="showArtLink(index)"
                  @click="goToArtistPage(art.link)"
                >
                  <!-- <router-link :to="{ path: art.link }"> -->
                  <div class="tour-mode__art-dot"></div>
                  <!-- </router-link> -->
                </div>
                <!-- <router-link
                  class="tour-mode__art-link"
                  :class="{ visible: index === activeArt }"
                  :to="{ path: art.link }"
                > -->
                <div
                  class="tour-mode__art-link"
                  :class="{ visible: index === activeArt }"
                  @click="goToArtistPage(art.link)"
                >
                  <IconArrowTopLong
                    class="tour-mode__art-link-icon"
                    width="42"
                    height="7"
                    color="#fff"
                  ></IconArrowTopLong>
                  <span class="tour-mode__art-link-text">{{ art.author }}</span>
                </div>
                <!-- </router-link> -->
              </div>
            </div>
          </div>
          <div class="tour-mode__sidebar">
            <IconButton
              class="tour-mode__button button--outline-white button--ellipse button--lg"
              text="Exit Space"
              @click="$emit('exitToure')"
            >
              <IconArrowTopLong class="tour-mode__button-icon" width="42" height="7" color="#fff" />
            </IconButton>
            <p class="tour-mode__name-page">{{ tourePage.name }}</p>
            <!-- <Copyright class="tour-mode__copyright"></Copyright> -->
          </div>
        </div>
      </transition>
      <ZoomZone
        :zoomZoneActive="zoomZoneActive"
        :zoomZone="zoomZone"
        @zoomOut="zoomOut"
        @exitToure="exitToureFromZoom"
      />
    </div>
  </transition>
</template>

<script>
import Copyright from "@/components/Copyright.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlusBold.vue";
import ZoomZone from "@/components/interiors/ZoomZone.vue";
import IconArrowTopLong from "@/components/icons/IconArrowTopLong.vue";

export default {
  components: {
    Copyright,
    BaseButton,
    IconButton,
    IconPlus,
    ZoomZone,
    IconArrowTopLong,
  },
  props: {
    tourActive: {
      type: Boolean,
      default: false,
    },
    tourePage: {
      type: Object,
      default: {
        name: "[Name]",
        img: "",
        zoomZones: [],
        arts: [],
      },
    },
  },
  data() {
    return {
      activeArt: null,
      zoomZoneActive: false,
      zoomZone: {
        coordinatesActive: ["0%", "0%"],
      },
    };
  },

  watch: {
    tourePage() {
      if (this.$route.query["zoom-zone"]) this.zoomIn(this.$route.query["zoom-zone"]);
    },
  },

  methods: {
    showArtLink(index) {
      this.activeArt = index;
    },
    async zoomIn(id) {
      if (!this.$route.query["zoom-zone"]) {
        this.$router.push({
          query: { ...this.$route.query, "zoom-zone": id },
        });
      }
      this.zoomZone = await this.tourePage.zoomZones[id];
      this.zoomZoneActive = true;
    },
    zoomOut() {
      const { "zoom-zone": _, ...restQueries } = this.$route.query; //remove zoom-zone from query
      this.$router.push({ query: restQueries });
      this.zoomZoneActive = false;
    },
    exitToureFromZoom() {
      this.$emit("exitToure");
      this.zoomZoneActive = false;
    },
    goToArtistPage(link) {
      if (link) {
        this.$router.push({ path: link });
      }
    },
    getCoordinates(coordinates) {
      if (coordinates[2]) return { right: coordinates[2], top: coordinates[1] };
      else return { left: coordinates[0], top: coordinates[1] };
    },
  },
};
</script>

<style lang="scss" scoped>
.tour-mode {
  position: relative;
  height: 100vh;
  width: 100vw;
  @media (max-width: $xxs) {
    width: 1440px;
  }
  &__image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    @media (max-width: $xxs) {
      width: auto;
      max-width: none;
    }
    @media (max-width: $xxs) {
      width: 1440px;
      max-width: none;
    }
  }
  &-page {
    position: absolute;
    height: 100%;
    width: 100%;
    /* display: flex;
    flex-direction: column; */
  }
  &__fade {
    &-enter-active {
      transition: all 1s linear;
    }
    &-leave-active {
      transition: all 1s linear;
    }
    &-enter {
      opacity: 0;
      &-to {
        opacity: 1;
      }
    }
    &-leave {
      opacity: 1;
      &-to {
        opacity: 0;
      }
    }
  }
  &__content {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.42); */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__button {
    width: max-content;
    border: none;
    height: max-content;
    &-zoom {
      position: absolute;
      width: max-content;
      min-width: 18rem;
      transform: translate(-50%, -50%);
    }
    &-icon {
      width: auto;
      height: auto;
      transform: rotate(-90deg);
    }
  }
  &__name-page {
    font-family: $fontBodoni;
    color: $white;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.47px;
    @media (max-width: $xxs) {
    }
    @media (max-height: 800px) {
    }
  }
  &__control {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    :nth-child(10) {
      z-index: 1;
    }
  }
  &__art {
    position: absolute;
    display: flex;
    align-items: center;

    &.reverse {
      flex-direction: row-reverse;
      .tour-mode__art {
        &-circle {
          margin-left: 10px;
        }
        &-link {
          flex-direction: row-reverse;
          &-icon {
            margin-left: 5px;
            transform: rotate(90deg);
          }
        }
      }
    }
    &-circle {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.56);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
    }
    &-dot {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background-color: $white;
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 5rem;
      width: max-content;
      min-width: 24rem;
      border-radius: 34px;
      padding: 0 24px;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: all 0.3s ease;
      z-index: -1;
      cursor: pointer;
      &:focus:focus-visible {
        opacity: 1;
        z-index: 1;
      }
      &.visible {
        opacity: 1;
        z-index: 1;
      }
      &-text {
        color: $white;
        font-family: $fontBodoni;
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        width: 100%;
        @media (max-width: $xxxs) {
          font-size: 1.6rem;
        }
      }
      &-icon {
        margin-right: 10px;
        width: auto;
        height: auto;
        transform: rotate(-90deg);
      }
    }
  }
  &__sidebar {
    background-color: $lightBlack;
    position: absolute;
    bottom: 0;
    padding: 2rem 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-height: 800px) {
    }
  }
  &__copyright {
    margin-top: 1rem;
    @media (max-height: 800px) {
    }
  }
}
</style>
