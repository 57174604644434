<template>
  <transition name="zoom-zone__fade">
    <div class="zoom-zone" v-show="zoomZoneActive">
      <img
        class="zoom-zone__image"
        v-webp="zoomZone.img"
        :src="zoomZone.img"
        alt="Toure mode image"
      />
      <div class="zoom-zone__content">
        <div class="zoom-zone__control">
          <IconButton
            :style="{ left: zoomZone.coordinatesActive[0], top: zoomZone.coordinatesActive[1] }"
            class="zoom-zone__button-zoom button--outline-black button--ellipse button--lg button--right-icon"
            text="Zoom Out"
            @click="$emit('zoomOut')"
          >
            <template #right>
              <IconMinus width="30" height="15" color="#fff" />
            </template>
          </IconButton>
          <div
            v-for="(art, index) in zoomZone.arts"
            :key="`art-${art.id}`"
            class="zoom-zone__art"
            :class="{ reverse: art.alignment === 'Left' }"
            :style="getCoordinates(art.coordinates)"
            @mouseleave="showArtLink(null)"
          >
            <div class="zoom-zone__art-circle" @mouseover="showArtLink(index)">
              <!-- <router-link :to="{ path: art.link }"> -->
              <div class="zoom-zone__art-dot" @click="goToArtistPage(art.link)"></div>
              <!-- </router-link> -->
            </div>
            <!-- <router-link
              class="zoom-zone__art-link"
              :class="{ visible: index === activeArt }"
              :to="{ path: art.link }"
            > -->
            <div
              class="zoom-zone__art-link"
              :class="{ visible: index === activeArt }"
              @click="goToArtistPage(art.link)"
            >
              <IconArrowTopLong
                class="zoom-zone__art-link-icon"
                width="42"
                height="7"
                color="#fff"
              />
              <span class="zoom-zone__art-link-text">{{ art.author }}</span>
            </div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
      <div class="zoom-zone__sidebar">
        <IconButton
          class="zoom-zone__button button--outline-white button--ellipse button--lg"
          text="Exit Space"
          @click="$emit('exitToure')"
        >
          <IconArrowTopLong class="zoom-zone__button-icon" width="42" height="7" color="#fff" />
        </IconButton>
        <p class="zoom-zone__name-page">{{ zoomZone.name }}</p>
        <!-- <Copyright class="zoom-zone__copyright"></Copyright> -->
      </div>
    </div>
  </transition>
</template>

<script>
import Copyright from "@/components/Copyright.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconMinus from "@/components/icons/IconMinus.vue";
import IconArrowTopLong from "@/components/icons/IconArrowTopLong.vue";

export default {
  components: {
    Copyright,
    BaseButton,
    IconButton,
    IconMinus,
    IconArrowTopLong,
  },
  props: {
    zoomZoneActive: {
      type: Boolean,
      default: false,
    },
    zoomZone: {
      type: Object,
      default: {
        name: "[Name]",
        coordinates: ["0%", "0%"],
        coordinatesActive: ["0%", "0%"],
        img: "",
        arts: [],
      },
    },
  },
  data() {
    return {
      activeArt: null,
    };
  },
  methods: {
    showArtLink(index) {
      this.activeArt = index;
    },
    goToArtistPage(link) {
      if (link) {
        this.$router.push({ path: link });
      }
    },
    getCoordinates(coordinates) {
      if (coordinates[2]) return { right: coordinates[2], top: coordinates[1] };
      else return { left: coordinates[0], top: coordinates[1] };
    },
  },
};
</script>

<style lang="scss" scoped>
.zoom-zone {
  position: relative;
  height: 100vh;
  width: 100vw;
  @media (max-width: $xxs) {
    width: 1440px;
  }
  &__image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    @media (max-width: $xxs) {
      width: auto;
      max-width: none;
    }
    @media (max-width: $xxs) {
      width: 1440px;
      max-width: none;
    }
  }
  &__fade {
    &-enter-active {
      transition: all 1s linear;
    }
    &-leave-active {
      transition: all 1s linear;
    }
    &-enter {
      opacity: 0;
      &-to {
        opacity: 1;
      }
    }
    &-leave {
      opacity: 1;
      &-to {
        opacity: 0;
      }
    }
  }
  &__content {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.42); */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__button {
    width: max-content;
    border: none;
    height: max-content;
    &-zoom {
      position: absolute;
      width: max-content;
      min-width: 18rem;
      transform: translate(-50%, -50%);
    }
    &-icon {
      width: auto;
      height: auto;
      transform: rotate(-90deg);
    }
  }
  &__name-page {
    font-family: $fontBodoni;
    color: $white;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: -0.47px;
    @media (max-width: $xxs) {
    }
  }
  &__control {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
  }
  &__art {
    position: absolute;
    display: flex;
    align-items: center;
    &.reverse {
      flex-direction: row-reverse;
      .zoom-zone__art {
        &-circle {
          margin-left: 10px;
        }
        &-link {
          flex-direction: row-reverse;
          &-icon {
            margin-left: 5px;
            transform: rotate(90deg);
          }
        }
      }
    }
    &-circle {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.56);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
    }
    &-dot {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background-color: $white;
    }
    &-link {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 5rem;
      width: max-content;
      min-width: 24rem;
      border-radius: 34px;
      padding: 0 24px;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.56);
      opacity: 0;
      transition: all 0.3s ease;
      z-index: -1;
      cursor: pointer;
      &:focus:focus-visible {
        opacity: 1;
        z-index: 1;
      }
      &.visible {
        opacity: 1;
        z-index: 1;
      }
      &-text {
        color: $white;
        font-family: $fontBodoni;
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        width: 100%;
        @media (max-width: $xxxs) {
          font-size: 1.6rem;
        }
      }
      &-icon {
        margin-right: 5px;
        width: auto;
        height: auto;
        transform: rotate(-90deg);
      }
    }
  }
  &__sidebar {
    background-color: $lightBlack;
    position: absolute;
    bottom: 0;
    padding: 2rem 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-height: 800px) {
    }
  }
  &__copyright {
    margin-top: 1rem;
  }
}
</style>
