<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        :stroke="color"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-width="3"
        d="M23.05694516 7.5569495l-16-.00000867"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 30 15" },
    width: { type: String, default: "30" },
    height: { type: String, default: "15" },
    color: { type: String, default: "#000" },
  },
};
</script>