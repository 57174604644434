<template>
  <svg :width="width" :height="height" :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
    <g :fill="color">
      <path d="M35.7294 3.48001L35.7294 5.50667L7.14932 5.50667L7.14932 3.48001L35.7294 3.48001Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.14898 9L7.14898 -2.49858e-06L5.37997e-05 4.49333L7.14898 9Z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 36 9" },
    width: { type: String, default: "36" },
    height: { type: String, default: "9" },
    color: { type: String, default: "#000" },
  },
};
</script>
