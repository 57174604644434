<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color">
      <path
        fill-rule="evenodd"
        d="M0 14.99993896C0 6.72906494 6.7290001 0 15 0s15 6.72906494 15 14.99993896C30 23.27102661 23.2709999 30 15 30S0 23.27102661 0 14.99993896zm2.72727203.00012208C2.72727203 21.7672119 8.2328186 27.27267456 15 27.27267456s12.27272797-5.5053711 12.27272797-12.27270508C27.27272797 8.23287964 21.7671814 2.727417 15 2.727417S2.72727203 8.23287964 2.72727203 15.00006104z"
      />
      <path
        fill-rule="evenodd"
        d="M15 11.81879066c-.7530909 0-1.36363636.61057691-1.36363636 1.36370662v9.0898319c0 .7531297.61054545 1.36370662 1.36363636 1.36370662.7530909 0 1.36363636-.61057691 1.36363636-1.36370662v-9.0898319c0-.7530388-.61054545-1.36370662-1.36363636-1.36370662z"
      />
      <path
        fill-rule="evenodd"
        d="M15 6.36423695c-.3590909 0-.7100909.14546203-.96454545.39911146-.25363637.25364944-.39909091.60548574-.39909091.96459515 0 .3591094.14545454.71003658.3990909.96459515.25363637.25364943.60545455.39911147.96454546.39911147.3590909 0 .71-.14546204.96445455-.39911147.25363636-.25455857.39918181-.60548574.39918181-.96459515 0-.3591094-.14554545-.71094571-.39918181-.96459515C15.71 6.50960807 15.3590909 6.36423695 15 6.36423695z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 30 30" },
    width: { type: String, default: "30" },
    height: { type: String, default: "30" },
    color: { type: String },
  },
};
</script>