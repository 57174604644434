<template>
  <svg :width="width" :height="height" :viewBox="viewBox">
    <g :fill="color" transform="rotate(-180.000032 21.215 3.375)">
      <rect width="1.52" height="37" x="20.45" y="-17.84" rx="0" ry="0" />
      <path fill-rule="evenodd" d="M24.59 19.16h-6.75l3.37 5.43 3.38-5.43z" />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    viewBox: { type: String, default: "0 0 42 7" },
    width: { type: String, default: "42" },
    height: { type: String, default: "7" },
    color: { type: String, default: "#000" },
  },
};
</script>